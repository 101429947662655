<template>
  <div id="promises-orders-list">
    <b-row>
      <b-col cols="12">
        <b-card no-body class="mb-0">
          <div class="m-2">
            <b-row>
              <b-col md="6" sm="12">
                <b-form-group label="Proyectos" label-for="projects">
                  <v-select
                    v-model="filtersForm.project"
                    label="name"
                    placeholder="Selecciona un Proyecto"
                    :options="projects"
                    :reduce="projects => projects.id">
                    <span slot="no-options">
                      No se encontraron opciones
                    </span>
                  </v-select>
                </b-form-group>
              </b-col>
              <b-col md="6" sm="12">
                <b-form-group label="Estado" label-for="status">
                  <v-select
                    v-model="filtersForm.status"
                    label="name"
                    placeholder="Selecciona un estado"
                    :options="status"
                    :reduce="status => status.id">
                    <span slot="no-options">
                      No se encontraron opciones
                    </span>
                  </v-select>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" class="mt-2">
        <b-card no-body class="mb-0">
          <div class="m-2">
            <b-overlay
              :show="overlayList"
              opacity="1.0"
              spinner-small
              spinner-variant="primary"
              @hidden="_onHidden()">
              <div class="d-flex align-items-end justify-content-end">
                  <b-overlay
                    :show="overlayForm"
                    opacity="0.5"
                    spinner-small
                    spinner-variant="primary"
                    class="d-inline-block"
                    @hidden="_onHidden()" >
                    <b-button
                      ref="button"
                      variant="primary"
                      :disabled="overlayForm"
                      @click.prevent="_exportPromisesRequests()" v-if="permissions_visible.download_promise_orders">
                      <feather-icon
                        icon="DownloadIcon"
                        class="mr-50"/>
                      <span class="align-middle">
                        Descargar Pagos de Promesas
                      </span>
                    </b-button>
                  </b-overlay>
                </div>
              <div class="mt-2">
                <b-table
                  hover
                  striped
                  show-empty
                  responsive="xl"
                  ref="refPromisesRequestListTable"
                  class="position-relative mb-1 "
                  :items="promisesRequests.data"
                  :fields="tableColumns"
                  primary-key="id"
                  empty-text="Datos no Disponibles">
                  <template #cell(fecha)="data">
                    {{ data.item.created_at | moment("DD-MM-YYYY") }}
                  </template>
                  <template #cell(status)="data">
                    <b-badge pill :variant="_getStatusColor(data.item.status)">
                      {{ _getStatus(data.item.status) }}
                    </b-badge>
                  </template>
                  <template #cell(quotation.project.name)="data">
                    {{ data.item.quotation.project != null ? data.item.quotation.project.name : '-' }}
                  </template>
                  <template #cell(quotation.customer.name)="data">
                    {{ data.item.quotation.customer.name + ' ' + data.item.quotation.customer.last_name }}
                  </template>
                  <template #cell(actions)="data">
                    <b-dropdown variant="link" no-caret
                      :right="$store.state.appConfig.isRTL">
                      <template #button-content>
                        <feather-icon size="16"
                          icon="MoreVerticalIcon"
                          class="align-middle text-body"/>
                      </template>
                      <b-dropdown-item @click.prevent="_handleSelected(data.item)" v-if="permissions_visible.edit_promise_order">
                        <feather-icon icon="ArrowRightCircleIcon" />
                          <span class="align-middle ml-50">Detalles</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </template>
                </b-table>
                <b-pagination
                  align="right"
                  v-model="currentPage"
                  hide-goto-end-buttons
                  :total-rows="promisesRequests.total"/>
              </div>
            </b-overlay>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import {mapActions, mapState, mapGetters} from 'vuex'
  import ToastificationContent from "@core/components/toastification/ToastificationContent"

  export default {
    name: 'PromisesOrdersList',
    data () {
      return {
        permissions_visible:{
          delete_promise_order:false,
          show_promise_orders:false,
          download_promise_orders:false,
          edit_promise_order:false,
        },
        overlayList: false,
        overlayForm: false,
        currentPage: 1,
        totalPage: 0,
        status: [
          {id: 'canceled', name:'Cancelada'},
          {id: 'pending', name:'Pendiente'},
          {id: 'paid', name:'Pagada'}
        ],
        filtersForm: {
          project: 0,
          status: '',
          page: 1
        },
        tableColumns: [
          {key: 'id', sortable: false, label: 'ID'},
          {key: 'quotation.customer.name', sortable: true, label: 'Nombre cliente'},
          {key: 'quotation.customer.email', sortable: true, label: 'Correo'},
          {key: 'quotation.project.name', sortable: true, label: 'Proyecto'},
          {key: 'status', sortable: true, label: 'Estado'},
          {key: 'created_at', sortable: true, label: 'Fecha creación'},
          {key: 'actions', sortable: false, label: 'Acciones'},
        ],
      }
    },
    mounted(){
      this.permissions_visible = this.$PermissionHelper.checkPermissions(this.permissions_visible);
    },
    beforeMount() {
      this.filtersForm.page = this.currentPage
      this._fetchPromisesRequests(this.filtersForm)

    },
    watch: {
      filtersForm: {
        handler(filter){
          this._fetchPromisesRequests(filter)
        },
        deep: true
      },
      currentPage (val) {
        this.filtersForm.page = this.currentPage
        this._fetchPromisesRequests(this.filtersForm)
      }
    },
    computed: {
      ...mapState('promises',['promisesRequests']),
      ...mapGetters('profile',['projects']),
    },
    methods: {
      ...mapActions('promises',['exportPromisesRequest', 'fetchPromisesRequests']),
      async _fetchPromisesRequests (filter) {
        //this.overlayList = true
        await this.fetchPromisesRequests(filter)
        .finally(() => {
          //this.overlayList = false
        })
      },
      async _exportPromisesRequests() {
        this.overlayForm = true
        await this.exportPromisesRequest(this.filtersForm)
        .then(res => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Se ha enviado un correo con el archivo',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          })
        }).catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error al exportar los datos',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        }).finally(() => {
          this.overlayForm = false
        })
      },
      _getStatusColor(status){
        switch (status) {
          case 'paid':
            return 'light-success'
          case 'canceled':
            return 'light-danger'
          case 'pending':
            return 'light-warning'
          default:
            return 'primary'
        }
      },
      _getStatus(status){
        switch (status) {
          case 'paid':
            return 'Pagada'
          case 'canceled':
            return 'Cancelada'
          case 'pending':
            return 'Pendiente'
          default:
            return status
        }
      },
      _handleSelected(data) {
        this.$router.push({ name: 'promises-orders-details', params: { id: data.id, promises: data } })
      },
      _onHidden() {
        this.$refs.button.focus()
      },
    },
  }
</script>