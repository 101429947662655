<template>
  <div id="promises-orders">
    <promises-orders-list></promises-orders-list>
  </div>
</template>

<script>
  import PromisesOrdersList from '../promises/components/PromisesOrdersList'

  export default{
    name: 'PromisesOrdersView',
    components: {PromisesOrdersList}
  }
</script>
